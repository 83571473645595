import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import MainProvider from '@onlypay/default-main-provider'
import { CONFIG } from '@onlypay/core-default'
import { THEMES } from '@onlypay/theme-default'

import routes from './Routes'
const Theme = THEMES[CONFIG.THEME],
  noop = () => {}

ReactDOM.render(
  <BrowserRouter>
    <MainProvider>
      <Theme>
        <Switch>
          {routes.map((route, key) => (
            <Route
              exact
              key={key}
              component={route.component}
              path={route.path}
              {...route}
            />
          ))}
        </Switch>
      </Theme>
    </MainProvider>
  </BrowserRouter>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if (process.env.NODE_ENV !== 'development') {
  console.log = noop
  console.warn = noop
  console.error = noop
}
