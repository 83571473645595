import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { MainContext } from '@onlypay/default-main-provider'
import { CompanyContext } from '@onlypay/default-company-provider'
import { META } from '@onlypay/core-default'

import { _MainAbout } from './styles'

const About = () => {
  const mainContext = useContext(MainContext), { about } = useContext(CompanyContext)

  useEffect(() => mainContext.methods.changePageTitle('Sobre nós'), [])

  return (
    <_MainAbout>
      <h4>A {META.TITLE}</h4>
      <div dangerouslySetInnerHTML={{__html: about}} />
      <p>
        *Caso tenha alguma dúvida acesse nosso <Link to="/fale-conosco"><span>FAQ</span></Link> ou envie nos um
          e-mail preenchendo o formulario em <Link to="/fale-conosco"><span>Fale Conosco</span></Link>.
      </p>
    </_MainAbout>
  )
}

export default About
