import LoginPage from './pages/Login'
import UserProfileIncompletePage from './pages/Login/UserProfileIncomplete'
import ResetPasswordPage from './pages/Login/ResetPassword'
import HomePage from './pages/Home'
import NewsPage from './pages/News'
import DownloadCenterPage from './pages/DownloadCenter'
import CampaignsPage from './pages/Campaigns'
import CatalogPage from './pages/Catalog'
import RegulationsPage from './pages/Regulations'
import ProfilePage from './pages/Profile'
import QuizPage from './pages/Quiz'
import ContactPage from './pages/Contact'
import AboutPage from './pages/About'
import { NotFound as NotFoundPage } from '@onlypay/theme-default'

const routes = [
  {
    path: '/',
    title: 'Home',
    component: HomePage,
    exact: true,
    onlyLogged: false,
    hidden: false,
    showToMobile: true,
  },
  {
    path: '/login',
    title: 'Login',
    component: LoginPage,
    onlyLogged: false,
    hidden: true,
    showToMobile: false,
  },
  {
    path: '/login/completar-cadastro',
    title: 'Completar meus cadastro',
    component: UserProfileIncompletePage,
    onlyLogged: true,
    hidden: true,
    showToMobile: false,
  },
  {
    path: '/login/esqueci-minha-senha',
    title: 'Esqueci minha senha',
    component: ResetPasswordPage,
    onlyLogged: false,
    hidden: true,
    showToMobile: false,
  },
  {
    path: '/sobre-nos',
    title: 'Sobre nós',
    component: AboutPage,
    onlyLogged: false,
    hidden: false,
    showToMobile: true,
  },
  {
    path: '/meus-dados',
    title: 'Meus Dados',
    component: ProfilePage,
    onlyLogged: true,
    hidden: true,
    showToMobile: true,
  },
  {
    path: '/quiz',
    title: 'Quiz',
    component: QuizPage,
    onlyLogged: true,
    hidden: true,
    showToMobile: true,
  },
  {
    path: '/campanhas',
    title: 'Campanhas',
    component: CampaignsPage,
    onlyLogged: false,
    showToMobile: true,
  },
  /*
  {
    path: '/catalogo-de-premios',
    title: 'Catálogo',
    component: CatalogPage,
    onlyLogged: false,
    hidden: false,
    showToMobile: true,
  },
  {
		path: '/premios',
		title: 'Prêmios',
		component: PointsListPage,
    onlyLogged: true,
		hidden: true,
    showToMobile: true,
  },
  */
  {
    path: '/regulamento',
    title: 'Regulamento',
    component: RegulationsPage,
    onlyLogged: false,
    hidden: true,
    showToMobile: false,
  },
  {
    path: '/novidades',
    title: 'Novidades',
    component: DownloadCenterPage,
    onlyLogged: true,
    hidden: true,
    showToMobile: true,
  },
  /*{
		path: '/faq',
		title: 'FAQ',
		component: ContactPage,
    onlyLogged: true,
		hidden: true,
    showToMobile: false,
  },*/
  {
    path: '/fale-conosco',
    title: 'Fale conosco',
    component: ContactPage,
    onlyLogged: false,
    showToMobile: true,
    props: {
      custom: 'contact',
    },
  },
  {
    path: '/sair',
    title: 'Sair',
    component: LoginPage,
    onlyLogged: true,
    hidden: true,
  },
  {
    path: '*',
    title: 'NotFound',
    component: NotFoundPage,
    onlyLogged: false,
    hidden: true,
    showToMobile: false,
  },
]

export default routes
