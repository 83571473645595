import styled from 'styled-components'
import { colors, device, convertHexToRGBA } from '@onlypay/core-default'

export const SCROLL = {
  BG: 'rgba(0, 171, 204, 1)',
  HOVER: 'rgba(0, 171, 204, 0.8)',
}

export const _Style = styled.div`
  .onlypay-header {
    height: 80px;

    a.logo {
      width: 100%;
      height: 80px;
      background-size: auto 85%;
    }

    @media ${device.tablet} {
      & > div {
        & > div {
          &:nth-child(2) {
            flex-grow: 1;
          }
        }
      }

      a.logo {
        width: 100%;
        height: 80px;
      }
    }

    @media ${device.iPadMini} {
      a.logo {
        width: 100%;
        height: 80px;
      }
    }

    @media ${device.iPadMini} and (orientation: portrait) {
      a.logo {
        width: 100%;
        height: 80px;
      }
    }
  }

.onlypay-FormContactAssociate > div > p { display: none; }
`
