export const CONFIG = {
  COMPANY_ID: 'DB94D6AB-5100-470E-9A6F-8C0B83E24D2B',
  API_URL:
    process.env.REACT_APP_NODE_ENV !== 'production'
      ? 'https://onlypay-campanhas-hotsite-api-hml.azurewebsites.net/api'
      : 'https://onlypay-campanhas-hotsite-api-wa.azurewebsites.net/api',
  TENANT_ID: '28344D5A-01E8-4F16-BE2D-6AA7B3B13C88',
  CATALOG_WSDL:
    'https://onlypay.com.br:3032/https://catalogo.pontosonly.com.br/pontosonlysinglesignon.asmx?WSDL',
  CATALOG_LOGIN_URL: 'https://catalogo.pontosonly.com.br/Login/SSO',

  BASE_CAMPAIGN_ID: '366D2FD5-415B-4FD5-BAF3-E335B51A1FBB',

  THEME: 'default',

  MODULES: {
    LOGIN: {
      DISABLED: false,

      // CADASTRO POR CNPJ DA COMPANY
      signupByCompany: false,
    },
    HEADER: { SHOW_POINTS: { DISABLED: true } },
    HOMESLIDER: {
      DISABLED: false,
      BANNER_TYPE_NAME: 'Home - Full'
    },
    HOMEGRID: {
      DISABLED: false,
      BANNERS: {
        LEFT: { BANNER_TYPE_NAME: 'Vertical Esquerda' },
        RIGHT: { BANNER_TYPE_NAME: 'Vertical Direita' },
      },
    },
    CATALOG: { DISABLED: true },
    FOOTER: {
      COLUMNS: { LEFT: { HIDDEN: true }, RIGHT: { HIDDEN: false } },
      FORM_CONTACT_ASSOCIATE: { DISABLED: false },
      COMPANY_CONTACT_INFOS: { DISABLED: true },
    },
  },
}
