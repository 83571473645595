export const FAVICON_URL = '/images/favicon.png'
export const LOGO_URL = '/images/logo.svg'
export const LOGO_ICON_URL = '/images/logo_icon_192x192.png'
export const LOGO_WHITE_URL = '/images/logo.svg'
export const LOGO_BLUE_URL = '/images/logo.svg'
export const LOGO_SLOGAN_BLUE_H_URL = '/images/logo.svg'

export const GA_UA = null
export const META = {
  NAME: 'Elanco Brasil',
  TITLE: 'Elanco Brasil - Alimento e Companheirismo enriquecendo a vida',
  DESCRIPTION: 'Na Elanco, nós fornecemos aqueles que criam e cuidam de animais com soluções que lhes permitam avançar uma visão de alimento e companheirismo enriquecendo a vida.',
  COMPANY: 'Elanco Brasil - Alimento e Companheirismo enriquecendo a vida',
  SUBJECT: 'Elanco Brasil - Alimento e Companheirismo enriquecendo a vida',
  KEYWORDS: 'Elanco Brasil - Alimento e Companheirismo enriquecendo a vida',
  RATING: 'general',
  GOOGLEBOT: 'index, follow',
  GOOGLE_SITE_VERIFICATION: '',
  AUDIENCE: 'all',
  REPLY_TO: 'fernanda.fernandes@elancoah.com',
  CANONICAL: 'https://www.parceirosbovinos.com.br/',
  CONTENT_LANGUAGE: 'pt-br',
  THEME_COLOR: '#0072CE',
  LOGO_ICON: '/images/logo_icon_192x192.png',
  OG_IMAGE: '/images/logo_icon_192x192.png',
}

export const M_QUERIES_VERSION = null

export const CAMPAIGN_NAME = 'Elanco Brasil'

export const SOCIAL_NETWORK = {
  FACEBOOK: 'https://www.facebook.com/ElancoBrasil',
  INSTAGRAM: 'https://www.instagram.com/elancobrasil',
  YOUTUBE: 'https://www.youtube.com/c/ElancoSaúdeAnimal',
  LINKEDIN: 'https://www.linkedin.com/company/elanco',
  WHATSAPP: null,
}

export const avatarMenuList = [
  {
    label: 'Meus Dados',
    attrs: {
      to: '/meus-dados',
      title: 'Meus Dados',
    },
  },
  {
    label: 'Novidades',
    attrs: {
      to: '/novidades',
      title: 'Novidades',
    },
  },
  {
    label: 'Quiz',
    attrs: {
      to: '/quiz',
      title: 'Conheça nosso Quiz',
    },
  },
]

export const sitemapList = [
  {
    path: '/sobre-nos',
    title: 'Sobre nós',
    listPosition: 0
  },
  {
    path: '/campanhas',
    title: 'Campanhas',
    listPosition: 0
  },
  {
    path: '/novidades',
    title: 'Novidades',
    listPosition: 1
  },
  {
    path: '/fale-conosco',
    title: 'Contato',
    listPosition: 1
  },
]

export const colors = {
  /* STATUS */
  ERROR: '#ED4B68',
  WARNING: '#FFC701',
  SUCCESS: '#4CAF50',
  SUCCESS_BOOTSTRAP: '#28a745',
  DISABLED: '#666666',

  /* GLOBAL */
  TRANSPARENT: 'transparent',
  BLACK: '#000000',
  BLUE: '#00ABCC',
  WHITE: '#FFFFFF',
  GRAY: '#CCCCCC',
  GRAY_LIGHT: '#EDF2F6',
  GRAY_DEFAULT: '#444444',
  GRAY_DARK: '#9A9FBF',
  GRAY_DARKER: '#9B9B9B',
  GREEN: '#BCDEA1',
  YELLOW: '#FFC701',
  MEDIA: '#FFC701',
  DESIGN: '#ED4B68',
  TECHNOLOGY: '#84C9F2',
  CONTENT: '#BCDEA1',
  PERFORMANCE: '#BCDEA1',
  OTHER: '#EEEEEE',

  BG_HEADER: '#F2F2F2',
  BG_FOOTER: '#141414',

  THEME: {},

  COMPONENTS: {},

  BG_LIGHT: '#F2F2F2',
  PRIMARY: '#0072CE',
  // SECONDARY: '#0FC9C3',
  SECONDARY: '#4d4d4f',
  LOADING: '#0072CE',
  LINK_COLOR: '#191919',
  GRAY: {
    LVL0: '#F5F5F5',
    LVL1: '#DDDDDD',
    LVL2: '#BCBCBC',
    LVL3: '#838383',
    LVL4: '#141414',
  },
}

colors.THEME = {
  BG_HEADER: '#F2F2F2',
  COLOR_MENU_ICON_MOBILE: '#FFFFFF',
  BG_FOOTER: '#141414',
  BG_SCROLL_TO_TOP: '#344898',
}

colors.COMPONENTS = {
  TABS: {
    TEXT_ACTIVE: '#BCBCBC',
  },
  FORM: {
    USER_LOGIN: { INPUT_MARGIN_BOTTOM: '0.5vh', INPUT_PWD_MARGIN_BOTTOM: '2.8vh' },
    USER_FIRST_LOGIN: { INPUT_MARGIN_BOTTOM: '3.5vh' },
    USER_INCOMPLETE: { INPUT_MARGIN_BOTTOM: '3.5vh' },
    USER_PROFILE: { INPUT_MARGIN_BOTTOM: '2.6vh' },
    USER_ADDRESS: { INPUT_MARGIN_BOTTOM: '2.6vh' },
    UPDATE_PASSWORD: { INPUT_MARGIN_BOTTOM: '2.6vh' },
    CONTACT: { INPUT_MARGIN_BOTTOM: '2vh' },
    INPUT_PADDING: '2.5vh 1.6vw',
    RADIO_PADDING: '2.5vh',
    TEXT_COLOR: '#767676',
    LABEL_FONT_FAMILY: '"Gotham Bold", sans-serif',
    LABEL_FONT_SIZE: '2.5vh',
    LABEL_COLOR: '#344898',
    LABEL_UPPERCASE: true,
    LABEL_ITALIC: false,
    PLACEHOLDER_FONT_FAMILY: '"Gotham Bold", sans-serif',
    PLACEHOLDER_FONT_SIZE: '1.7vh',
    PLACEHOLDER_COLOR: '#767676',
    PLACEHOLDER_UPPERCASE: true,
    PLACEHOLDER_ITALIC: true,
    PLACEHOLDER_FONT_WEIGHT: '400',
    BUTTON_FONT_FAMILY: '"Montserrat", sans-serif',
    BUTTON_FONT_SIZE: '2vh',
    BUTTON_LINE_HEIGHT: '2.5vh',
    BUTTON_COLOR: '#344898',
    BUTTON_LABEL_COLOR: '#FFFFFF',
    BUTTON_UPPERCASE: true,
    BUTTON_ITALIC: false,
    BUTTON_FONT_WEIGHT: '500',
    BUTTON_BORDER_RADIUS: '.25rem',
  },
}
